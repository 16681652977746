import axios from 'axios';
import store from '@/store'
import { MessageBox, Message  } from 'element-ui'

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    baseURL: 'https://demo.exchange.afca-bigdata.com',
});

service.interceptors.request.use(
    config => {
      if (store.state.app.accessToken) {
        config.headers.Authorization = store.state.app.accessToken
      }
      return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            Promise.reject();
        }
    },
    error => {
      if (error.response.status === 403) { // token失效 ,重新获取token
        MessageBox.confirm('登录状态已过期，请重新登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('accessToken','')
          window.location.href = '/'
          // location.reload()
        })
      } else if (error.response.status === 500) {
        Message.error(error.response.data.message)
      }
        return Promise.reject();
    }
);

export default service;
