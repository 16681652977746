<template>
  <div>
    <el-drawer
      @close="closes"
      size="350px"
      title="认证管理"
      :visible.sync="drawer"
      :wrapperClosable="false"
      :direction="direction"
      :before-close="handleClose">
      <p class="back" v-if="backBtn" @click="toBack">返回></p>
      <div class="auth" v-if="renzheng">
        <div @click="toPerson(verify)">
          <p :class="[verify == '1'?'active':'disActive']">
            {{ verify == '1'? '已认证':'未认证' }}
          </p>
          个人认证
        </div>
        <!-- <div @click="toCom">
          <p :class="[verify == '2'?'active':'disActive']">
            {{ verify == '2'? '已认证':'未认证' }}
          </p>
          企业认证
        </div> -->
      </div>
      <div class="person" style="padding:0 20px 20px 20px" v-if="personal">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="名字" prop="realName">
            <el-input v-model="ruleForm.realName"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="idcard">
            <el-input v-model="ruleForm.idcard"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="submitForm('ruleForm')" style="margin-top:10px">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="padding:0 20px 20px 20px" v-if="componey">
        <div>
          <p class="upload-type">
            <span>*</span> 公司名称
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入公司名称" />
          <p class="upload-type">
            <span>*</span> 统一社会信用代码/营业执照注册号
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入统一社会信用代码/营业执照注册号" />
          <p class="upload-type">
            <span>*</span> 注册地址
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入注册地址" />
          <p class="upload-type">
            <span>*</span> 法定代表人
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入法定代表人" />
          <p class="upload-type">
            <span>*</span> 公司类型
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入公司类型" />
          <p class="upload-type">
            <span>*</span> 经营范围
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入经营范围" />
          <p class="upload-type">
            <span>*</span> 经营期限
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入经营期限" />
          <p class="upload-type">
            <span>*</span> 登记机关
          </p>
          <el-input style="max-width:400px;margin-top:10px" placeholder="请输入登记机关" />
          <div style="padding:10px 0;padding-left:160px">
            <el-button type="primary" size="small" disabled>确定</el-button>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { verify } from '../api/index';
export default {
  name: 'Index',
  props: {
      shows: {
        type: Boolean,
        required: false
      }
    },
  components: {
  },
  mounted() {
    this.verify = localStorage.getItem('verify')
    // setInterval(this.startPlay, 2000)
  },
  data() {
    return {
      verify: '',
      drawer: this.shows,
      direction: 'rtl',
      personal: false,
      componey: false,
      renzheng: true,
      backBtn: false,
      ruleForm: {
        realName: '',
        idcard: ''
      },
      rules: {
        realName: [
          { required: true, message: '请输入名字', trigger: 'blur' },
        ],
        idcard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          { min: 18, max: 18, message: '请输入18位身份证号码', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
      shows(val) {
        this.drawer = val
        this.renzheng = true
        this.personal = false
        this.componey = false
        this.backBtn = false
        this.verify = localStorage.getItem('verify')
        console.log(this.verify)
      }
    },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          verify(this.ruleForm).then(res => {
            if (res.code === 0) {
              this.$message.success('认证成功');
              localStorage.setItem('verify', 1)
              this.drawer = false
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    toBack() {
      this.backBtn = false
      this.renzheng = true
      this.personal = false
      this.componey = false
    },
    closes() {
      this.$emit('update:shows', false)
    },
    todetail() {
      this.$router.push('./detail')
    },
    handleClose(done) {
      done()
    },
    toPerson(data) {
      console.log(data)
      if (data === '1') {
        return
      } else {
        this.renzheng = false
        this.personal = true
        this.backBtn = true
        this.ruleForm = {}
      }
    },
    toCom() {
      this.renzheng = false
      this.componey = true
      this.backBtn = true
    }
  }
}
</script>
<style scoped>
.active {
  background: rgb(32, 129, 226) !important;
}
.back{
  color: rgb(32, 129, 226);
  cursor: pointer;
  font-size: 12px;
  text-align: right;
  padding-right: 20px;
}
.auth {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.auth div {
  position: relative;
  text-align: center;
  line-height: 60px;
  border: 1px solid #eee;
  margin: 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.auth div p {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px;
  color: #fff;
  line-height: 10px !important;
  padding: 3px 4px;
  background: rgb(204, 204, 204);
  border-radius: 4px;
}
.auth div:hover {
  border: 1px solid rgb(32, 129, 226);
  color: rgb(32, 129, 226);
}
.upload-type {
  color: rgb(53, 56, 64);
  font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
}
.upload-type span {
  color: red;
}
</style>
<style>
.person .el-form-item__label {
  font-weight: bold;
}
.person .el-form-item {
  margin-bottom: 10px;
}
.person .el-form-item__content {
  text-align: center;
}
</style>