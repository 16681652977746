<template>
  <div>
    <el-drawer
      @close="closes"
      size="350px"
      title="登录"
      :wrapperClosable="false"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose">
      <!-- 密码、验证码登录 -->
      <el-tabs v-if="login" v-model="activeName" style="padding:10px 30px">
        <el-tab-pane label="账号登录" name="first">
          <el-form :model="pwdForm" :rules="pwdRules" ref="pwdForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="手机号" prop="passport">
              <el-input maxlength="11" v-model="pwdForm.passport" placeholder="请输入手机号" @keyup.enter.native="loginPwd('pwdForm')" />
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
              <el-input type="password" v-model="pwdForm.pwd" placeholder="请输入密码" @keyup.enter.native="loginPwd('pwdForm')" />
              <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
            </el-form-item>
            <span style="font-size:12px;margin-top:0px;display:inline-block;margin-bottom:10px;color:rgb(22, 119, 255);cursor:pointer" @click="reset">忘记密码</span>
            <el-form-item style="text-align:center">
              <el-button type="primary" size="small" style="width:100%" @click.native.prevent="loginPwd('pwdForm')">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <!-- <el-tab-pane label="验证码登录" name="second">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="手机号" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item label="验证码" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入验证码" />
              <span class="code" @click="tackBtn">{{valiBtn}}</span>
            </el-form-item>
            <el-form-item style="text-align:center">
              <el-button type="primary" size="small" style="width:100%">登录</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane> -->
        <div @click="create" style="margin-top:10px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1677ff;line-height: 20px;cursor: pointer;text-align:center">新用户注册？点击创建 》</div>
      </el-tabs>
      <!-- 注册账户 -->
      <el-form v-if="register" :model="registerForm" :rules="registerRules" ref="registerForm" label-width="100px" class="demo-ruleForm" style="padding:10px 30px">
        <el-form-item label="手机号" prop="phone">
          <el-input maxlength="11" v-model="registerForm.phone" placeholder="请输入手机号" @keyup.enter.native="submitForm('registerForm')" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input v-model="registerForm.pwd" type="password" placeholder="请设置账户密码" @keyup.enter.native="submitForm('registerForm')" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item label="确认密码" prop="pwd2">
          <el-input v-model="registerForm.pwd2" type="password" placeholder="请再次输入账户密码" @keyup.enter.native="submitForm('registerForm')" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="registerForm.code" placeholder="请输入验证码" @keyup.enter.native="submitForm('registerForm')" />
          <span class="code" @click="dialogTackBtn(valiBtn)">{{valiBtn}}</span>
        </el-form-item>
        <div class="rad">
          <el-radio v-model="radio" label="1">我已仔细阅读并同意</el-radio><a target="blank" href="#/privacys" @click.stop="privacy">《隐私政策》</a><span style="font-size:12px;color:#666">与</span><a target="blank" href="#/serviceagree" @click.stop="registerRule">《用户服务协议》</a>
        </div>
        <el-form-item style="text-align:center">
          <el-button @click.native.prevent="submitForm('registerForm')" type="primary" size="small" style="width:100%;margin-top:10px">注册</el-button>
        </el-form-item>
      </el-form>
      <!-- 忘记密码 -->
      <el-form v-if="reget" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="padding:10px 30px">
        <el-form-item label="账户名称" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入账户名称" />
        </el-form-item>
        <el-form-item label="手机号" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入手机号" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item label="验证码" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入验证码" />
          <span class="code" @click="tackBtn">{{valiBtn}}</span>
        </el-form-item>
        <el-form-item style="text-align:center">
          <el-button type="primary" size="small" style="width:100%;margin-top:10px" @click="Retrieve">确定</el-button>
        </el-form-item>
      </el-form>
      <!-- 重置密码 -->
      <el-form v-if="set" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" style="padding:10px 30px">
        <el-form-item label="密码" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请设置账户密码" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item label="确认密码" prop="name">
          <el-input v-model="ruleForm.name" placeholder="请再次输入账户密码" />
          <!-- <span class="code" @click="tackBtn">{{valiBtn}}</span> -->
        </el-form-item>
        <el-form-item style="text-align:center">
          <el-button type="primary" size="small" style="width:100%;margin-top:10px">确定</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 登录验证码 -->
    <el-dialog
      class="login-code"
      title="验证码校验"
      :visible.sync="dialogVisible"
      :closeOnClickModal = "false"
      :closeOnPressEscape = "false"
      :before-close="handleClose">
      <div style="display: flex;">
        <el-input v-model="loginCode" style="flex:1" placeholder="验证码" />
        <img @click="changeImg" :src="imgInfo.img" style="width:100px;margin-left: 6px;height: 39px;" alt="">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getVerify" size="mini">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 注册验证码 -->
    <el-dialog
      class="login-code"
      title="验证码校验1"
      :visible.sync="registerVisible"
      :closeOnClickModal = "false"
      :closeOnPressEscape = "false"
      :before-close="handleClose">
      <div style="display: flex;">
        <el-input v-model="registerCode" style="flex:1" placeholder="验证码" />
        <img @click="changeImg" :src="imgInfo.img" style="width:100px;margin-left: 6px;height: 39px;" alt="">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getRegitserVerify" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getCode, submit,smscode,signUp, login, verifyCode, getCodeBase } from '../api/index';
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        loginCode: '',//登录验证码
        registerCode: '', //注册验证码
        userCode:'',
        dialogVisible:false, //登录验证码弹框
        registerVisible: false, //注册验证码弹框
        proofInfo: {},
        set: false,
        reget:false,
        radio: '',
        activeName: 'first',
        valiBtn: "获取验证码",
        drawer: this.show,
        direction: 'rtl',
        ruleForm: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' }
          ]
        },
        login:true,
        register: false,
        // 注册
        registerForm: {},
        registerRules: {
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: this.validPhone }

          ],
          pwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
          ],
          pwd2: [
            { required: true, message: '请输入密码', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: this.validatePass },
             { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
          ],
          code:[
            { required: true, message: '请输入验证码', trigger: 'blur' },
          ]
        },
        imgInfo: {},
        imgInfos: {},
        // 密码登录
        pwdForm: {
          passport: '',
          pwd: ''
        },
        pwdRules: {
          passport: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
             { required: true, trigger: 'blur', validator: this.validPassport }
          ],
          pwd: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8,  message: '请输入8位或以上的密码', trigger: 'blur' }
          ]
        }
      }
    },
    watch:{
      show(val) {
        this.drawer = val
        this.activeName = 'first'
        this.reget = false
        this.register = false
        this.set = false
        this.login = true
        if (this.$refs.pwdForm !== undefined) {
          this.$refs.pwdForm.resetFields();
        }
        this.pwdForm = {}
      }
    },
    mounted() {
      this.pwdForm = {}
    },
    methods: {
      // 切换验证码图片
      changeImg() {
        getCode().then(res => {
          if (res.code == 0) {
            this.imgInfo = res.data
            this.loginCode = ''
            this.registerCode = ''
          } else {
            this.$message.error(res.message)
          }
        })
      },
      // 校验验证码
      getVerify() {
        let obj = {
          id: this.imgInfo.id,
          code: this.loginCode
        }
        verifyCode(obj).then(res => {
          if (res.code != 0) {
            this.$message.error(res.message)
            this.imgInfo = {}
            this.loginCode = ''
            this.getCodeImg()
          } else {
            this.dialogVisible = false
            this.pwdForm.accountType = 'phone'
            this.pwdForm.proof = res.data.proof
            this.pwdForm.proofId = this.imgInfo.id
            login(this.pwdForm).then(res => {
              if(res.code === 0) {
                this.$message.success('登录成功');
                this.getUser(res.data.userCode)
                this.$emit('func','Bearer ' + res.data.token)
                this.$store.dispatch('accessToken','Bearer ' + res.data.token)
                this.$store.dispatch('userCode',res.data.userCode)
                this.drawer = false
                this.$router.push('/')
                this.$parent.getList()
                this.$parent.getTypeList()
              } else {
                this.$message.error(res.message);
              }
            })
          }
        })
      },
      getUser(val) {
        let data={
          userCode: val
        }
        getCodeBase(data).then(res => {
          if (res.code == 0) {
            this.verify = res.data.idVerifyType
            localStorage.setItem('verify', res.data.idVerifyType)
          }else {
            this.$message.error(res.message)
          }
        })
      },
      // 注册校验验证码
      getRegitserVerify() {
        let obj = {
          id: this.imgInfo.id,
          code: this.registerCode
        }
        verifyCode(obj).then(res => {
          if (res.code != 0) {
            this.$message.error(res.message)
            this.imgInfo = {}
            this.registerCode = ''
            this.getCodeImg()
          } else {
            this.proofInfo = res.data
            // 是否注册
            this.getAvailable()
          }
        })
      },
      // 手机号是否注册
      getAvailable() {
        let data = {
          phone: this.registerForm.phone,
          proofId:this.imgInfo.id,
          proof: this.proofInfo.proof
        }
        submit(data).then(res => {
          if (res.code == 0) {
            this.registerVisible=false;
            this.smscodet()
          } else {
            this.$message.error(res.message)
            this.registerVisible=false
            // callback(new Error(res.message));
          }
        })
      },
      // 获取验证码图片
      getCodeImg() {
        getCode().then(res => {
          if (res.code == 0) {
            this.dialogVisible = true
            this.imgInfo = res.data
          } else {
            this.$message.error(res.message)
          }
        })
      },
      // 密码登录
      loginPwd(pwdForm){
        this.$refs[pwdForm].validate((valid) => {
          if (valid) {
            this.loginCode = ''
            this.getCodeImg()
            // this.pwdForm.accountType = 'phone'
            // login(this.pwdForm).then(res => {
            //   if(res.code === 0) {
            //     this.$message.success('登录成功');
            //     this.$emit('func','Bearer ' + res.data.token)
            //     // localStorage.setItem('tk', 'Bearer ' + res.data.token)
            //     // localStorage.setItem('userCode', res.data.userCode)
            //     this.$store.dispatch('accessToken','Bearer ' + res.data.token)
            //     this.$store.dispatch('userCode',res.data.userCode)
            //     this.drawer = false
            //     this.$router.push('/')
            //   } else {
            //     this.$message.error(res.message);
            //   }
            // })
          } else {
            return false;
          }
        });
      },
      // 发送验证码
      smscodet(){
        let dataCode = {
          phone: this.registerForm.phone,
          scene: 'reg',
          proofId: this.imgInfo.id,
          proof: this.proofInfo.proof
          }
          smscode(dataCode).then(resto => {
            if(resto.code===0){
              this.$message.success('验证码发送成功')
              this.tackBtn()
              // this.dialogVisible = false;
            } else {
              this.$message.error(resto.message);
              this.userCode = ''
              // this.changeImg()
            }
          })
      },
      dialogTackBtn(valiBtn){
        if (valiBtn === '获取验证码') {
          if (this.registerForm.phone !== '' && this.registerForm.phone !== undefined) {
            this.registerVisible=true;
            this.userCode = ''
            this.changeImg()
          } else {
            this.$message.error('请输入手机号');
          }
        }
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if(this.radio === '') {
              this.$message.error('请勾选用户服务协议');
              return
            }
            this.registerForm.passport = this.registerForm.phone
              signUp(this.registerForm).then(res => {
                if(res.code === 0) {
                  this.$message.success('账户创建成功');
                  this.register = false
                  this.login = true
                  if (this.$refs.pwdForm !== undefined) {
                    this.$refs.pwdForm.resetFields();
                  }
                  this.pwdForm = {}
                } else {
                  this.$message.error(res.message);
                }
             })
          } else {
            return false;
          }
        });
      },
      validatePass(rule, value, callback) {
      // 这里每一种情况都要callback，不然表单验证会失效  
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.registerForm.pwd) {
          callback(new Error('两次输入密码不一致!'))
        } else {
        callback()
        }
      },
      validPassport(rule, value,callback) {
        var reg=/^1[3456789]\d{9}$/;
          if (!value){
              callback(new Error('请输入电话号码'))
          }else  if(!reg.test(value)){
              callback(new Error('请输入有效的手机号码'));
          }else {
            callback()
         }
      },
       validPhone(rule, value,callback){
         var reg=/^1[3456789]\d{9}$/;
          if (!value){
              callback(new Error('请输入电话号码'))
          }else  if(!reg.test(value)){
              callback(new Error('请输入有效的手机号码'));
          } else {
            callback()
          }
      //     else {
      //       let data = {
      //         phone: value,
      //         imgCode: this.imgInfos.debug_img_code,
      //         imgId: this.imgInfos.id
      //       }
      //       submit(data).then(res => {
      //         if (res.code === 0) {
      //           callback()
      //         } else {
      //           callback(new Error(res.message));
      //         }
      //       })
      // }
  },
      closes() {
        this.$emit('update:show', false)
      },
      Retrieve() {
        this.reget = false
        this.set = true
      },
      registerRule() {
        window.open('../serviceagree')
        // this.$router.push('./serviceagree')
      },
      privacy() {
        window.open('../privacys')
        // window.location.href = process.env.VUE_APP_BASE_API + '/privacys'
        // this.$router.push('./privacys')
      },
      create() {
        this.registerForm = {   
        }
        this.login = false
        this.register = true
        this.radio = ''
        if (this.$refs.registerForm !== undefined) {
          this.$refs.registerForm.resetFields();
        }
        getCode().then(res => {
          if (res.code == 0) {
            this.imgInfos = res.data
          } else {
            this.$message.error(res.message)
          }
        });
      },
      reset() {
        this.login = false
        this.register = false
        this.reget = true
      },
      handleClose(done) {
        done()
      },
      getCode() {
        this.tackBtn(); //验证码倒数60秒
      },
      tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    }
  };
</script>
<style scoped>

</style>
<style>
.login-code {
  top: 20%;
  left: auto;
  bottom: auto;
}
.login-code .el-dialog {
  width: 84%;
}
.login-code .el-dialog__body {
  padding: 16px 20px;
}
.back {
  color: rgb(32, 129, 226);
  cursor: pointer;
  font-size: 12px;
  text-align: right;
  padding-right: 20px;
}
.el-form-item {
  display: flex;
  flex-direction: column;
}
.el-form-item .el-form-item__content {
  margin-left: 0px !important;
  max-width: 300px;
}
.code {
  float: right;
    margin-top: -40px;
    z-index: 999;
    position: relative;
    margin-right:4px;
    font-size: 12px;
    color: #409eff;
    cursor: pointer;
}
.el-form-item .el-form-item__label  {
  width: auto;
  text-align: left;
}
.rad .el-radio {
  margin-right: 0;
}
.rad .el-radio span{
  font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666 !important;
    line-height: 22px;
}
.rad a{
  font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #0091ff !important;
    line-height: 22px;
    cursor: pointer;
}
</style>
