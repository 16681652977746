import request from '../utils/request';
// 检测手机号是否注册
export const submit = query => {
  return request({
      url: '/api/user/phone-available',
      method: 'post',
      data: query
  });
};
// 获取图片验证码
export const getCode = query => {
  return request({
      url: '/api/captcha/get',
      method: 'get',
      data: query
  });
};

// 校验验证码
export const verifyCode = query => {
  return request({
      url: '/api/captcha/verify',
      method: 'post',
      data: query
  });
};

//获取用户信息
export const getCodeBase = query => {
  return request({
      url: '/api/user/base-info',
      method: 'get',
      params: query
  });
};
// 获取短信验证码
export const smscode = query => {
  return request({
      url: '/api/user/send-sms-code',
      method: 'post',
      data: query
  });
};
// 注册
export const signUp = query => {
  return request({
      url: '/api/user/sign-up',
      method: 'post',
      data: query
  });
};
// 登录
export const login = query => {
  return request({
      url: '/api/user/sign-in',
      method: 'post',
      data: query
  });
};
//个人认证
export const verify = query => {
  return request({
      url: '/api/user/verify-idcard',
      method: 'post',
      data: query
  });
};

//退出登录
export const signOut = query => {
  return request({
      url: '/api/user/sign-out',
      method: 'post',
      data: query
  });
};

//修改头像
export const changeAvatars = query => {
  return request({
      url: '/api/user/change-avatar',
      method: 'post',
      data: query
  });
};
//修改密码
export const changePwd = query => {
  return request({
      url: '/api/user/change-pwd',
      method: 'post',
      data: query
  });
};

//用户专辑列表
export const getAlbumList = query => {
  return request({
      url: '/api/asset-album/list-by-creator',
      method: 'get',
      params: query
  });
};

//专辑列表
export const getAlbum = query => {
  return request({
      url: '/api/asset-album/list',
      method: 'get',
      params: query
  });
};

//专辑详情
export const getAlbumDetail = query => {
  return request({
      url: '/api/asset-album/detail-by-title',
      method: 'get',
      params: query
  });
};

//获取分类
export const getType = query => {
  return request({
      url: '/api/asset-type/list',
      method: 'get',
      params: query
  });
};

//图片访问
export const downImg = query => {
  return request({
      url: '/api/picture/download',
      method: 'get',
      params: query,
      responseType: 'blob'
  });
};

//用户上传的图片
export const uploadImg = query => {
  return request({
      url: '/api/picture/upload',
      method: 'post',
      data: query
  });
};
//用户上传的图片
export const uploadImgSafe = query => {
  return request({
      url: '/api/picture/upload-safe',
      method: 'post',
      data: query
  });
};

//允许用户上传的图片格式
export const getAllow = query => {
  return request({
      url: '/api/user-picture/allow-ext',
      method: 'get',
      params: query
  });
};

//创建专辑
export const addAlbum = query => {
  return request({
      url: '/api/asset-album/add',
      method: 'post',
      data: query
  });
};

//创建藏品
export const addCollect = query => {
  return request({
      url: '/api/asset-collect/add',
      method: 'post',
      data: query
  });
};

//修改藏品
export const editCollect = query => {
  return request({
      url: '/api/asset-collect/edit',
      method: 'post',
      data: query
  });
};

//藏品列表
export const getCollectList = query => {
  return request({
      url: '/api/asset-collect/list',
      method: 'get',
      params: query
  });
};

//自己藏品列表
export const getCollectMyList = query => {
  return request({
      url: '/api/asset-collect/my-list',
      method: 'get',
      params: query
  });
};

//藏品详情
export const getCollectDetail = query => {
  return request({
      url: '/api/asset-collect/detail',
      method: 'get',
      params: query
  });
};

//编辑藏品详情
export const getEditDetail = query => {
  return request({
      url: '/api/asset-collect/edit-detail',
      method: 'get',
      params: query
  });
};

//收藏藏品
export const toCollect = query => {
  return request({
      url: '/api/asset-collect/collect',
      method: 'post',
      data: query
  });
};

//点赞藏品
export const toLike = query => {
  return request({
      url: '/api/asset-collect/like',
      method: 'post',
      data: query
  });
};

//允许用户上传的附件
export const getUpload = query => {
  return request({
      url: '/api/file/upload',
      method: 'post',
      data: query
  });
};

// 发布藏品
export const publish = query => {
  return request({
      url: '/api/asset-collect/publish',
      method: 'post',
      data: query
  });
};

//下单详情
export const getForm = query => {
  return request({
      url: '/api/asset-order/form',
      method: 'get',
      params: query
  });
};
// 待支付详情
export const toPaid = query => {
  return request({
      url: '/api/asset-order/to-paid',
      method: 'get',
      params: query
  });
};
//下单
export const toOrder = query => {
  return request({
      url: '/api/asset-order/order',
      method: 'post',
      data: query
  });
};

//售出
export const toBuyList = query => {
  return request({
      url: '/api/asset-order/my-list',
      method: 'get',
      params: query
  });
};

//收藏列表
export const getMyCollect = query => {
  return request({
      url: '/api/asset-collect/collect-list',
      method: 'get',
      params: query
  });
};

//点赞列表
export const getMyLike = query => {
  return request({
      url: '/api/asset-collect/like-list',
      method: 'get',
      params: query
  });
};

//支付藏品
export const payCollect = query => {
  return request({
      url: '/api/wechat-pay',
      method: 'post',
      data: query
  });
};

//支付回调
export const getNotify = query => {
  return request({
      url: '/api/asset-order/status',
      method: 'post',
      data: query
  });
};
